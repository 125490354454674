/* Base styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Content styles */
.main-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

/* Link styles - ensure consistency */
a {
  color: var(--bs-link-color);
  text-decoration: none;
  border-bottom: none;
}

a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: none;
}

/* Navigation styles */
.custom-nav .nav-link {
  border-radius: 4px;
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
}

.custom-nav .nav-link.active {
  background-color: var(--bs-nav-pills-link-active-bg) !important;
  color: white !important;
  text-decoration: none !important;
}

/* Header styles */
.header-brand {
  font-size: 1.5rem;
  font-weight: 500;
}

/* Dropdown menu styles */
.dropdown-menu {
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item:hover {
  background-color: rgba(0, 86, 179, 0.1);
}

/* Content typography */
.main-content h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.main-content h2 {
  font-size: 1.5rem;
  margin: 2rem 0 1rem;
  color: #333;
}

.main-content p {
  margin-bottom: 1.2rem;
}

/* Code blocks and pre */
pre {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  overflow-x: auto;
}

code {
  color: #e83e8c;
  background-color: #f8f9fa;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
}

/* Lists */
.main-content ul,
.main-content ol {
  padding-left: 1.5rem;
  margin-bottom: 1.2rem;
}

/* Images */
.main-content img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 1.5rem 0;
}

/* Blockquotes */
blockquote {
  border-left: 4px solid var(--bs-nav-pills-link-active-bg);
  padding-left: 1rem;
  margin: 1.5rem 0;
  color: #666;
}
