/* Mobile-first responsive styles */
@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .main-content {
    padding: 15px !important;
  }

  .nav-pills {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .dropdown-menu {
    width: 100%;
    text-align: center;
  }

  .sidebar {
    margin-top: 2rem;
  }

  .navbar-toggler {
    display: block;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  nav:not(.show) {
    display: none;
  }

  nav.show {
    display: block;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
  }

  .fs-4 {
    font-size: 1.2rem !important;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 991px) {
  .container {
    max-width: 95%;
  }
  
  .main-content {
    padding: 20px;
  }
}

/* Desktop styles */
@media (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }

  nav {
    display: block !important;
  }
}

/* General responsive improvements */
.main-content img {
  max-width: 100%;
  height: auto;
  margin: 1rem auto;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
}

.dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
}

/* Improved touch targets for mobile */
.nav-link, .dropdown-item {
  padding: 0.75rem 1rem;
}

/* Better spacing for mobile content */
.row {
  margin-left: -15px;
  margin-right: -15px;
}

[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

/* Fix for underlined titles */
.main-content h1,
.main-content h2,
.main-content h3,
.main-content h4,
.main-content h5,
.main-content h6 {
  text-decoration: none !important;
  border-bottom: none !important;
}

/* Add bottom margin to headers for better spacing */
.main-content h1,
.main-content h2 {
  margin-bottom: 1rem;
  color: #333;
}

/* Fix for header name readability */
.invertito {
  background-color: transparent;
  padding: 0.5rem 1rem;
  color: #58a;
  font-weight: 500;
  box-shadow: none;
  white-space: nowrap;
  display: inline-block;
  flex-shrink: 0;
}

/* Ensure header link container doesn't shrink */
.header-link {
  text-decoration: none;
  flex-shrink: 0;
}

/* Hover effect */
.invertito:hover {
  background-color: transparent;
  color: #fd7e14;
  transition: all 0.2s ease;
}

/* Bootstrap variable overrides - all colors in one place */
:root {
  --brand-blue: #58a;
  --brand-orange: #fd7e14;
  --bs-nav-pills-link-active-bg: #58a;
  --bs-link-color: #58a;
  --bs-link-hover-color: var(--brand-orange);
  --bs-link-color-rgb: 85, 136, 170;
  --bs-nav-link-color: #58a;
  --bs-nav-link-hover-color: var(--brand-orange);
  /* Button overrides */
  --bs-btn-bg: #58a;
  --bs-btn-border-color: #58a;
  --bs-btn-hover-bg: #4d7999;      /* Versione leggermente più scura per hover */
  --bs-btn-hover-border-color: #4d7999;
  --bs-btn-active-bg: #4d7999;
  --bs-btn-active-border-color: #4d7999;
}

/* Link styles globali - aumentata specificità */
a:not(.btn):not(.nav-link) {
  color: var(--brand-blue) !important;
  text-decoration: none !important;
}

a:not(.btn):not(.nav-link):hover {
  color: var(--brand-orange) !important;
}

/* Navigation pills */
.nav-pills .nav-link.active {
  background-color: var(--brand-blue) !important;
  color: white !important;
}

.nav-pills .nav-link {
  color: var(--bs-nav-link-color) !important;
}

.nav-pills .nav-link:hover:not(.active) {
  color: var(--bs-nav-link-hover-color) !important;
  background-color: rgba(85, 136, 170, 0.1);
}

/* Post title e headers */
.post-title {
  color: #343a40;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.main-content h1 {
  color: #000;
}

.main-content h2 {
  color: #333;
}

/* Links nel contenuto con specificità aumentata */
.main-content a:not(.btn) {
  color: var(--brand-blue) !important;
  text-decoration: none !important;
  border-bottom: 1px solid transparent;
}

.main-content a:not(.btn):hover {
  color: var(--brand-orange) !important;
  border-bottom-color: var(--brand-orange);
}

/* Dropdown menu */
.dropdown-item:hover {
  color: var(--brand-orange);
  background-color: rgba(85, 136, 170, 0.1);
}

/* Blockquote */
blockquote {
  border-left: 4px solid var(--brand-blue);
}

/* Navigation links */
.nav-link.custom-link {
  color: #58a !important;
}

.nav-link.custom-link:hover {
  color: var(--brand-orange) !important;
}

.nav-link.custom-link.active {
  background-color: #58a !important;
  color: white !important;
}

/* Aggiorniamo anche lo stile dei pulsanti per sicurezza */
.btn-primary {
  background-color: var(--brand-blue) !important;
  border-color: var(--brand-blue) !important;
  color: white !important;
  transition: all 0.2s ease !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--brand-orange) !important;
  border-color: var(--brand-orange) !important;
  color: white !important;
}

/* Remove Bootstrap's default focus shadow */
.btn:focus {
  box-shadow: none !important;
}

/* Optional: Add a subtle hover effect */
.btn {
  position: relative;
  overflow: hidden;
}

.btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.btn:hover::after {
  opacity: 1;
}